import { useState, useEffect, useCallback, useRef } from 'react'
import { LayoutProps } from '@sport1/types/web'
import { useRouter } from 'next/router'
import { getLayout, getLayoutByTag, getTagBySlug } from '@/pages/api/cms/content/tag/[id]'
import { getEntryTo } from '@/utils/urlTranslator'
import Config from '@/utils/Config'
import { useAdPlacementContext } from '@/context/AdPlacementContext/AdPlacementProvider'

type ReturnValue = {
    pagedLayout: LayoutProps
}

const useMediathekPaging = (slug?: string, layoutData?: LayoutProps): ReturnValue => {
    const currentPage = useRef(0)
    const isFetchingNextPage = useRef(false)
    const hasNextPage = useRef(true)
    const { asPath } = useRouter()
    const [currentLayoutData, setCurrentLayoutData] = useState(layoutData || { components: [] })
    const { enrichAdsWithId } = useAdPlacementContext()

    const loadNextPage = useCallback(() => {
        if (!isFetchingNextPage.current) {
            isFetchingNextPage.current = true
            currentPage.current = currentPage.current + 1
            if (slug) {
                Promise.all([getTagBySlug('/tv-video'), getEntryTo(asPath)]).then(
                    ([tag, layoutUrl]) => {
                        ;(asPath === '/tv-video'
                            ? getLayoutByTag(tag, currentPage.current)
                            : getLayout(`${Config.CMS_API}${layoutUrl}`, currentPage.current)
                        )
                            .then(layout => {
                                if (layout && layout.components.length > 0) {
                                    enrichAdsWithId(layout.components)
                                    setCurrentLayoutData({
                                        ...currentLayoutData,
                                        components: [
                                            ...currentLayoutData.components,
                                            ...layout.components,
                                        ],
                                    })
                                } else {
                                    hasNextPage.current = false
                                    window.removeEventListener('scroll', loadNextPage)
                                }
                            })
                            .catch(() => {
                                hasNextPage.current = false
                                window.removeEventListener('scroll', loadNextPage)
                            })
                    }
                )
            }
        }
    }, [asPath, currentLayoutData, enrichAdsWithId, slug])

    useEffect(() => {
        isFetchingNextPage.current = false
        if (hasNextPage.current && slug && layoutData) {
            window.addEventListener('scroll', loadNextPage)
        }
        return () => window.removeEventListener('scroll', loadNextPage)
    }, [loadNextPage, layoutData, slug])

    return { pagedLayout: currentLayoutData }
}

export default useMediathekPaging
